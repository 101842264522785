import React from 'react';
import ReactDOM from 'react-dom/client';
import RouteSettings from './route/RouteSettings';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   
    <RouteSettings />
    
);


